<script>

export default {
  components: {
  },
  data() {
    return {
      pieChart : {
        series: [44, 55, 41, 17, 15],
        chartOptions: {
            labels: [],
            colors: ["#34c38f", "#556ee6", "#f46a6a", "#50a5f1", "#f1b44c"],
            legend: {
                show: false,
                position: 'bottom',
                horizontalAlign: 'center',
                verticalAlign: 'middle',
                floating: false,
                fontSize: '14px',
                offsetX: 0
            },
            responsive: [{
                breakpoint: 600,
                options: {
                    chart: {
                        height: 240
                    },
                    legend: {
                        show: false
                    },
                }
            }]
        }
    }
    };
  },
  
  methods: {

    
  },

  mounted() {
    
  },
  props: {
    customer:{
      type: Object,
    },
  },
  watch : {}
};
</script>

<template>
  <div>
    <div class="row">
      <b-tabs justified nav-class="nav-tabs-custom" content-class="p-3 text-muted">
        <b-tab active>
          <template v-slot:title>
            <span class="d-inline-block d-sm-none">
              
            </span>
            <span class="d-none d-sm-inline-block"> Resumen</span>
          </template>
          <div class="row">
            <div class="col-sm-4">
              <div class="card">
                <div class="card-body">
                    <label class="card-title mb-3">Datos básicos</label>
                    <div class="row">
                      <div class="col">
                        <i class="bx bx-key"></i> <label class="mb-1">2000</label> <br>
                        <i class="bx bx-envelope"></i> <label class="mb-1"> melobor@gmail.com</label> <br>
                        <i class="bx bx-phone"></i> <label class="mb-1"> 12313123123123</label> <br>
                        <i class="bx bx-money"></i> <label class="mb-1">Loyal customers with high spend</label> <br>
                        <button class="btn btn-link btn-sm">+ Propiedades</button> <br>
                        <span class="badge bg-primary font-size-10"> web</span> <span class="badge bg-primary font-size-10"> app</span>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
            <div class="col-xl-8">
              <div class="row">
                <h5 class="mb-3">Últ. 90 días:</h5>
                <div class="col-sm-4">
                  <div class="card">
                    <div class="card-body">
                      <div class="d-flex align-items-center mb-1">
                        <div class="avatar-xs me-3">
                          <span
                            class="
                              avatar-title
                              rounded-circle
                              bg-soft bg-primary
                              text-primary
                              font-size-18
                            "
                          >
                            <i class="bx bx-copy-alt"></i>
                          </span>
                        </div>
                        <h5 class="font-size-14 mb-0">Pedidos</h5>
                      </div>
                      <div class="text-muted mt-2">
                        <h4>
                          1,452
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-sm-4">
                  <div class="card">
                    <div class="card-body">
                      <div class="d-flex align-items-center mb-1">
                        <div class="avatar-xs me-3">
                          <span
                            class="
                              avatar-title
                              rounded-circle
                              bg-soft bg-primary
                              text-primary
                              font-size-18">
                            <i class="bx bx-dollar"></i>
                          </span>
                        </div>
                        <h5 class="font-size-14 mb-0">Monto Total</h5>
                      </div>
                      <div class="text-muted mt-2">
                        <h4>
                          $ 28,452
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-sm-4">
                  <div class="card">
                    <div class="card-body">
                      <div class="d-flex align-items-center mb-1">
                        <div class="avatar-xs me-3">
                          <span
                            class="
                              avatar-title
                              rounded-circle
                              bg-soft bg-primary
                              text-primary
                              font-size-18
                            "
                          >
                            <i class="bx bx-purchase-tag-alt"></i>
                          </span>
                        </div>
                        <h5 class="font-size-14 mb-0">Average Price</h5>
                      </div>
                      <div class="text-muted mt-2">
                        <h4>
                          $ 16.2
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
        <!-- end row -->
            </div>
          </div>

        </b-tab>
        <b-tab>
          <template v-slot:title>
            <span class="d-inline-block d-sm-none">
              
            </span>
            <span class="d-none d-sm-inline-block"> Interacciones</span>
          </template>
        </b-tab> 
        <b-tab>
          <template v-slot:title>
            <span class="d-inline-block d-sm-none">
              
            </span>
            <span class="d-none d-sm-inline-block">Afinidad</span>
          </template>
          <div class="card">
            <div class="card-body">
              <label class="card-title mb-3">Afinidad</label>
              <div class="row">
                <div class="col-4">
                  
                </div>
                <div class="col-4">
                  
                </div> 
                <div class="col-4">
                  
                </div> 

                <div class="col-4">
                  
                </div> 
              </div>
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<style scoped>
  
</style>